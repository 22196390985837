<template>
  <div class="provider-order-page">
    <div v-if="!pageLoad" class="page-loader">
      <div class="spinner-border text-primary" role="status">
        <span class="sr-only">Loading...</span>
      </div>
    </div>

    <div class="page-title d-flex pt-2 pb-3 justify-content-between">
      <h1 class="h4 m-0">Заказ на поставку</h1>

      <div class="d-flex align-items-center">
        <BButton
          class="ml-3"
          variant="warning"
          @click="$bvModal.show('import-orders')"
          >Экспорт
        </BButton>
      </div>
    </div>

    <b-tabs v-model="tabIndex" card>
      <b-tab title="Информация">
        <b-row>
          <b-col cols="6">
            <div class="d-flex table-bordered mb-1">
              <div
                class="px-2 py-1 d-flex align-items-center font-weight-bold border-right bg-light text-muted flex-shrink-0"
                style="width: 250px"
              >
                Номер заказа
              </div>
              <div class="px-2 py-1 flex-grow-1">
                {{ providerOrder.number }}
              </div>
            </div>
          </b-col>

          <b-col cols="6">
            <div class="d-flex table-bordered mb-1">
              <div
                class="px-2 py-1 d-flex align-items-center font-weight-bold border-right bg-light text-muted flex-shrink-0"
                style="width: 250px"
              >
                Дата заказа
              </div>
              <div class="px-2 py-1 flex-grow-1">
                {{ providerOrder.order_date | parseDate }}
              </div>
            </div>
          </b-col>
        </b-row>

        <b-row>
          <b-col cols="6">
            <div class="d-flex table-bordered mb-1">
              <div
                class="px-2 py-1 d-flex align-items-center font-weight-bold border-right bg-light text-muted flex-shrink-0"
                style="width: 250px"
              >
                № договора
              </div>
              <div class="px-2 py-1 flex-grow-1">
                {{ providerOrder.contract_number }}
              </div>
            </div>
          </b-col>

          <b-col cols="6">
            <div class="d-flex table-bordered mb-1">
              <div
                class="px-2 py-1 d-flex align-items-center font-weight-bold border-right bg-light text-muted flex-shrink-0"
                style="width: 250px"
              >
                Дата договора
              </div>
              <div class="px-2 py-1 flex-grow-1">
                {{ providerOrder.contract_date | parseDate }}
              </div>
            </div>
          </b-col>

          <b-col cols="12">
            <div class="d-flex table-bordered mb-1">
              <div
                class="px-2 py-1 d-flex align-items-center font-weight-bold border-right bg-light text-muted flex-shrink-0"
                style="width: 250px"
              >
                Этап договора
              </div>
              <div class="px-2 py-1 flex-grow-1">
                {{ providerOrder.contract_stage }}
              </div>
            </div>
          </b-col>
        </b-row>

        <div class="d-flex table-bordered mb-1">
          <div
            class="px-2 py-1 d-flex align-items-center font-weight-bold border-right bg-light text-muted flex-shrink-0"
            style="width: 250px"
          >
            Год
          </div>
          <div class="px-2 py-1 flex-grow-1">
            {{ getYear(providerOrder.order_date) }}
          </div>
        </div>

        <div class="d-flex table-bordered mb-1">
          <div
            class="px-2 py-1 d-flex align-items-center font-weight-bold border-right bg-light text-muted flex-shrink-0"
            style="width: 250px"
          >
            Поставщик
          </div>

          <div class="px-2 py-1 flex-grow-1">
            {{ getContrAgent && getContrAgent.name }}
          </div>
        </div>

        <div class="d-flex table-bordered mb-1">
          <div
            class="px-2 py-1 d-flex align-items-center font-weight-bold border-right bg-light text-muted flex-shrink-0"
            style="width: 250px"
          >
            Филиал заказчик
          </div>
          <div class="px-2 py-1 flex-grow-1">
            {{ getOrganization && getOrganization.name }}
          </div>
        </div>

        <b-row>
          <b-col cols="6">
            <div class="d-flex table-bordered mb-1">
              <div
                class="px-2 py-1 d-flex align-items-center font-weight-bold border-right bg-light text-muted flex-shrink-0"
                style="width: 250px"
              >
                Ответственный
              </div>
              <div class="px-2 py-1 flex-grow-1">
                {{ providerOrder.responsible_full_name }}
              </div>
            </div>
          </b-col>

          <b-col cols="6">
            <div class="d-flex table-bordered mb-1">
              <div
                class="px-2 py-1 d-flex align-items-center font-weight-bold border-right bg-light text-muted flex-shrink-0"
                style="width: 250px"
              >
                Контактный телефон
              </div>
              <div class="px-2 py-1 flex-grow-1">
                {{
                  providerOrder.responsible_phone | VMask("# (###) ###-##-##")
                }}
              </div>
            </div>
          </b-col>
        </b-row>

        <div class="d-flex table-bordered mb-1">
          <div
            class="px-2 py-1 d-flex align-items-center font-weight-bold border-right bg-light text-muted flex-shrink-0"
            style="width: 250px"
          >
            Комментарий
          </div>
          <div class="px-2 py-1 flex-grow-1">
            {{ providerOrder.organization_comment }}
          </div>
        </div>
      </b-tab>

      <b-tab title="Заказы">
        <div class="switch-checkbox-custom">
          <span
            :class="{ active: ordersListType === 1 }"
            @click="ordersListType = 1"
            >Основной</span
          >
          <span
            :class="{ active: ordersListType === 2 }"
            @click="ordersListType = 2"
            >Актуальный</span
          >
        </div>

        <div v-show="ordersListType === 1">
          <TableFilter
            :columns="positionsFields"
            uniqueKey="table-2"
            @update-selected-columns="providerOrderListColumns = $event"
            @search="baseListFilter = $event"
            @open-filter-modal="
              $root.$emit('bv::toggle::collapse', 'base-orders-filter')
            "
            @clear="clearBaseListFilter"
          />

          <DefaultTable
            :data="providerOrder.base_positions"
            :fields="positionsFieldsFiltered"
            :filter="baseListFilter"
            :withSpecifiedTotal="getBasePositionsTotal"
            :loading="baseOrdersLoading"
            :noLocalSorting="true"
            emptyText="Нет заказов"
            @sort-change="baseSorting"
            @pagination-change="basePaginationChange"
            @per-page-change="basePerPageChange"
          />
        </div>

        <div v-show="ordersListType === 2">
          <TableFilter
            :columns="positionsFields"
            uniqueKey="table-3"
            @update-selected-columns="providerOrderListColumns = $event"
            @search="actualListFilter = $event"
            @open-filter-modal="
              $root.$emit('bv::toggle::collapse', 'actual-orders-filter')
            "
            @clear="clearActualListFilter"
          />

          <DefaultTable
            :data="providerOrder.actual_positions"
            :fields="positionsFieldsFiltered"
            :withSpecifiedTotal="getActualPositionsTotal"
            :filter="actualListFilter"
            :loading="actualOrdersLoading"
            :noLocalSorting="true"
            emptyText="Нет заказов"
            @sort-change="actualSorting"
            @pagination-change="actualPaginationChange"
            @per-page-change="actualPerPageChange"
          />
        </div>
      </b-tab>

      <b-tab title="Корректировка потребности">
        <DefaultTable
          :data="providerOrderAdjustmentsList"
          :fields="adjustmentsFields"
          :rowLink="this.$route.path + '/adjustments'"
          emptyText="Нет корректировок"
          :noLocalSorting="true"
          :loading="adjustmentsLoading"
          @sort-change="adjustmentsSorting"
          @pagination-change="adjustmentsPaginationChange"
          @per-page-change="adjustmentsPerPageChange"
        />
      </b-tab>

      <b-tab title="Корректировка заказа">
        <DefaultTable
          :data="providerOrderCorrectionsList"
          :fields="correctionsFields"
          :rowLink="this.$route.path + '/order-corrections'"
          emptyText="Нет корректировок"
          :noLocalSorting="true"
          :loading="orderCorrectionsLoading"
          @sort-change="orderCorrectionsSorting"
          @pagination-change="orderCorrectionsPaginationChange"
          @per-page-change="orderCorrectionsPerPageChange"
        />
      </b-tab>
    </b-tabs>

    <b-modal id="import-orders" centered title="Экспорт заказа поставщику">
      <b-form-group
        label="Выберите заказы для экспорта:"
        v-slot="{ ariaDescribedby }"
      >
        <b-form-radio-group
          id="radio-group-1"
          v-model="selectedOrderTypeOption"
          :options="selectOrderTypeOptions"
          :aria-describedby="ariaDescribedby"
          name="radio-options"
          stacked
        />
      </b-form-group>

      <template #modal-footer="{ hide }">
        <b-button variant="outline-primary" @click="hide()"> Отмена </b-button>
        <b-button variant="primary" @click="exportTable">
          Импортировать
        </b-button>
      </template>
    </b-modal>

    <FilterSidebar
      id="base-orders-filter"
      v-model="baseFilterData"
      @apply="updateBaseOrdersWithFilters(true)"
    />

    <FilterSidebar
      id="actual-orders-filter"
      v-model="actualFilterData"
      @apply="updateActualOrdersWithFilters(true)"
    />
  </div>
</template>

<script>
import { mapState } from "vuex";
import TableFilter from "@/components/TableFilter";
import DefaultTable from "@/components/Tables/Default";
import FilterSidebar from "@/components/FilterSidebar";

function initialOrderFilterData() {
  return {
    nomenclature_name: "",
    mnemocode: "",
  };
}

export default {
  name: "ProviderOrder",
  components: {
    TableFilter,
    DefaultTable,
    FilterSidebar,
  },
  computed: {
    ...mapState({
      providerOrderAdjustmentsList: (state) =>
        state.providerOrders.currentProviderOrder.requirement_corrections,
      providerOrderCorrectionsList: (state) =>
        state.providerOrders.currentProviderOrder.order_corrections,
      contrAgents: (state) => state.referenceBooks.contrAgents,
      organizations: (state) => state.referenceBooks.organizations,
      providerOrder: (state) => state.providerOrders.currentProviderOrder,
    }),

    id() {
      return this.$route.params.id;
    },

    getContrAgent() {
      return this?.providerOrder?.provider_contr_agent;
      // return this.contrAgents.find((el) => el.id === this.providerOrderId);
    },

    getOrganization() {
      return this.organizations.find((el) => el.id === this.organizationId);
    },
    getBasePositionsTotal() {
      const withVat =
        this.providerOrder.common_amount_with_vat_in_base_positions;
      const withoutVat =
        this.providerOrder.common_amount_without_vat_in_base_positions;
      if (withVat && withoutVat) {
        return `Всего сумма по документу: без НДС - ${withoutVat} руб., с НДС - ${withVat} руб.`;
      }
      return `Всего сумма по документу: без НДС - Не указано, с НДС - Не указано`;
    },
    getActualPositionsTotal() {
      return `Всего сумма по документу: без НДС - ${
        this.providerOrder.common_amount_without_vat_in_actual_positions ||
        "Не указано"
      } руб., с НДС - ${
        this.providerOrder.common_amount_with_vat_in_actual_positions ||
        "Не указано"
      } руб.`;
    },
    positionsFieldsFiltered() {
      let res = [];
      this.positionsFields.forEach((item) => {
        if (
          this.providerOrderListColumns.filter((col) => col === item.label)
            .length
        ) {
          res.push(item);
        }
      });
      return res.length > 0 ? res : this.positionsFields;
    },
    breadcrumbs() {
      return [
        { title: "Главная", link: "/" },
        { title: "Заказ СК", link: "/" },
        {
          title: "Перечень заказов поставщику",
          link: "/provider/purchase-orders",
        },
        { title: `Заказ на поставку № ${this.providerOrder.number}` },
      ];
    },
  },
  data() {
    return {
      providerOrderId: null,
      organizationId: null,
      tabIndex: 0,
      ordersListType: 1,
      providerOrderListColumns: [],
      providerOrderListFilter: "",
      filterData: {},
      selectOrderTypeOptions: [
        {
          value: 1,
          text: "Основной",
        },
        {
          value: 2,
          text: "Актуальный",
        },
      ],
      selectedOrderTypeOption: 1,
      pageLoad: false,
      positionsFields: [
        {
          key: "mnemocode",
          sortable: true,
          label: "Мнемокод",
          full: "Мнемокод",
          openDefault: true,
        },
        {
          key: "nomenclature_name",
          sortable: true,
          label: "Номенклатура",
          full: "Номенклатура",
          openDefault: true,
        },
        {
          key: "count",
          sortable: true,
          label: "Кол-во",
          full: "Количество",
          openDefault: true,
        },
        {
          key: "unit",
          sortable: true,
          label: "Ед. изм.",
          full: "Единица измерения",
          openDefault: true,
        },
        {
          key: "price_without_vat",
          sortable: true,
          label: "Цена ₽ (без НДС)",
          full: "Цена ₽ (без НДС)",
          openDefault: true,
        },
        {
          key: "amount_without_vat",
          sortable: true,
          label: "Сумма ₽ (без НДС)",
          full: "Сумма ₽ (без НДС)",
          openDefault: true,
        },
        {
          key: "vat_rate",
          sortable: true,
          label: "Ставка НДС",
          full: "Ставка НДС",
          openDefault: true,
        },
        {
          key: "amount_with_vat",
          sortable: true,
          label: "Сумма ₽ (с НДС)",
          full: "Сумма ₽ (с НДС)",
          openDefault: true,
        },
        {
          key: "delivery_time",
          sortable: true,
          label: "Срок поставки",
          full: "Срок поставки",
          openDefault: true,
        },
        {
          key: "delivery_address",
          sortable: true,
          label: "Адрес поставки",
          full: "Адрес поставки",
          openDefault: true,
        },
        {
          key: "organization_comment",
          sortable: true,
          label: "Комм",
          full: "Комментарий",
          openDefault: true,
        },
      ],
      adjustmentsFields: [
        {
          key: "date",
          sortable: true,
          label: "Дата",
          full: "Дата",
          openDefault: true,
        },
        {
          key: "number",
          sortable: true,
          label: "№ корректировки",
          full: "Номер корректировки",
          openDefault: true,
        },
        {
          key: "provider_status",
          sortable: true,
          label: "Статус",
          full: "Статус",
          openDefault: true,
        },
      ],
      correctionsFields: [
        {
          key: "date",
          sortable: true,
          label: "Дата",
          full: "Дата",
          openDefault: true,
        },
        {
          key: "number",
          sortable: true,
          label: "№ корректировки",
          full: "Номер корректировки",
          openDefault: true,
        },
      ],
      baseListFilter: "",
      actualListFilter: "",
      baseFilterData: initialOrderFilterData(),
      actualFilterData: initialOrderFilterData(),
      baseOrdersLoading: false,
      actualOrdersLoading: false,
      adjustmentsLoading: false,
      orderCorrectionsLoading: false,
      sortingBaseOrdersData: {
        sort_field: "",
        sort_order: "",
        page: "",
        per_page: 25,
      },
      sortingActualOrdersData: {
        sort_field: "",
        sort_order: "",
        page: "",
        per_page: 25,
      },
      sortingAdjustmentsData: {
        sort_field: "",
        sort_order: "",
        page: "",
        per_page: 25,
      },
      sortingOrderCorrectionsData: {
        sort_field: "",
        sort_order: "",
        page: "",
        per_page: 25,
      },
    };
  },

  watch: {
    providerOrder: {
      handler(value) {
        if (value && value.provider_contr_agent_id) {
          this.providerOrderId = value.provider_contr_agent_id;
        }
        if (value && value.organization_id) {
          this.organizationId = value.organization_id;
        }
      },
      deep: true,
    },
  },

  methods: {
    async getData() {
      this.pageLoad = false;
      await Promise.all([
        this.$store.dispatch("getCurrentProviderOrder", this.id),
        this.$store.dispatch("getActualPositions", { id: this.id }),
        this.$store.dispatch("getBasePositions", { id: this.id }),
        this.$store.dispatch("getRequirementCorrections", { id: this.id }),
        this.$store.dispatch("getOrderCorrections", { id: this.id }),
      ]);
      this.pageLoad = true;
    },
    exportTable() {
      this.$bvModal.hide("import-orders");
    },
    getYear(date) {
      if (date) {
        return date.split("-")[0];
      }
    },
    clearBaseListFilter() {
      this.baseListFilter = "";
      this.baseFilterData = initialOrderFilterData();
      this.updateBaseOrdersWithFilters(true);
    },
    clearActualListFilter() {
      this.actualListFilter = "";
      this.actualFilterData = initialOrderFilterData();
      this.updateActualOrdersWithFilters(true);
    },
    async updateBaseOrdersWithFilters(clearSort) {
      this.baseOrdersLoading = true;
      if (clearSort) {
        this.sortingBaseOrdersData.sort_field = "";
        this.sortingBaseOrdersData.sort_order = "";
        this.sortingBaseOrdersData.page = "";
      }
      await this.$store.dispatch("getBasePositions", {
        id: this.id,
        filters: {
          ...this.sortingBaseOrdersData,
          ...this.baseFilterData,
        },
      });
      this.baseOrdersLoading = false;
    },
    async updateActualOrdersWithFilters(clearSort) {
      this.actualOrdersLoading = true;
      if (clearSort) {
        this.sortingActualOrdersData.sort_field = "";
        this.sortingActualOrdersData.sort_order = "";
        this.sortingActualOrdersData.page = "";
      }
      await this.$store.dispatch("getActualPositions", {
        id: this.id,
        filters: {
          ...this.sortingActualOrdersData,
          ...this.actualFilterData,
        },
      });
      this.actualOrdersLoading = false;
    },
    async updateAdjustmentsWithFilters() {
      this.adjustmentsLoading = true;
      await this.$store.dispatch("getRequirementCorrections", {
        id: this.id,
        filters: this.sortingAdjustmentsData,
      });
      this.adjustmentsLoading = false;
    },
    async updateOrderCorrectionsWithFilters() {
      this.orderCorrectionsLoading = true;
      await this.$store.dispatch("getOrderCorrections", {
        id: this.id,
        filters: this.sortingOrderCorrectionsData,
      });
      this.orderCorrectionsLoading = false;
    },
    baseSorting(event) {
      this.sortingBaseOrdersData.sort_field = event.sortBy;
      this.sortingBaseOrdersData.sort_order = event.sortDesc ? "desc" : "asc";
      this.updateBaseOrdersWithFilters();
    },
    basePaginationChange(page) {
      this.sortingBaseOrdersData.page = page;
      this.updateBaseOrdersWithFilters();
    },
    basePerPageChange(perPage) {
      this.sortingBaseOrdersData.page = "";
      this.sortingBaseOrdersData.per_page = perPage;
      this.updateBaseOrdersWithFilters();
    },
    actualSorting(event) {
      this.sortingActualOrdersData.sort_field = event.sortBy;
      this.sortingActualOrdersData.sort_order = event.sortDesc ? "desc" : "asc";
      this.updateActualOrdersWithFilters();
    },
    actualPaginationChange(page) {
      this.sortingActualOrdersData.page = page;
      this.updateActualOrdersWithFilters();
    },
    actualPerPageChange(perPage) {
      this.sortingActualOrdersData.page = "";
      this.sortingActualOrdersData.per_page = perPage;
      this.updateActualOrdersWithFilters();
    },
    adjustmentsSorting(event) {
      this.sortingAdjustmentsData.sort_field = event.sortBy;
      this.sortingAdjustmentsData.sort_order = event.sortDesc ? "desc" : "asc";
      this.updateAdjustmentsWithFilters();
    },
    adjustmentsPaginationChange(page) {
      this.sortingAdjustmentsData.page = page;
      this.updateAdjustmentsWithFilters();
    },
    adjustmentsPerPageChange(perPage) {
      this.sortingAdjustmentsData.page = "";
      this.sortingAdjustmentsData.per_page = perPage;
      this.updateAdjustmentsWithFilters();
    },
    orderCorrectionsSorting(event) {
      this.sortingOrderCorrectionsData.sort_field = event.sortBy;
      this.sortingOrderCorrectionsData.sort_order = event.sortDesc
        ? "desc"
        : "asc";
      this.updateOrderCorrectionsWithFilters();
    },
    orderCorrectionsPaginationChange(page) {
      this.sortingOrderCorrectionsData.page = page;
      this.updateOrderCorrectionsWithFilters();
    },
    orderCorrectionsPerPageChange(perPage) {
      this.sortingOrderCorrectionsData.page = "";
      this.sortingOrderCorrectionsData.per_page = perPage;
      this.updateOrderCorrectionsWithFilters();
    },
  },
  async mounted() {
    const tab = this.$route.query.tab;
    if (tab) {
      this.tabIndex = parseInt(tab);
      setTimeout(() => {
        this.tabIndex = parseInt(tab);
      });
    }
    this.getData();
    this.$store.dispatch("checkNotification", {
      entity: "provider_orders",
      id: this.id,
    });
    this.positionsFields.forEach((item) => {
      if (item.openDefault) {
        this.providerOrderListColumns.push(item.label);
      }
    });
  },
};
</script>
